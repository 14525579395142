<template>
  <van-tabbar v-model="json.active" safe-area-inset-bottom>
    <van-tabbar-item icon="home-o" to="/index">统计</van-tabbar-item>
    <van-tabbar-item icon="share-o" to="/user">推广</van-tabbar-item>
    <van-tabbar-item icon="newspaper-o" to="/media">动态</van-tabbar-item>
  </van-tabbar>
</template>
<script>
export default {
  props: {
    json: {
      type: Object,
      default: function(){
        return {
          active: 0
        }
      }
    }
  }
}
</script>