<template>
  <div>
    <van-sticky>
      <div class="top">
        <div class="title">{{text}}</div>
        <van-icon name="jue-calendar" size="20" @click="show = true" />
        <!-- <van-button type="default" size="small" round
        ><van-icon name="jue-calendar" size="12"
      /></van-button> -->
      </div>
    </van-sticky>
    <van-popup v-model="show" round position="bottom">
      <div class="items">
        <div class="item" @click="selectDate(1)">
          <div class="icon">
            <van-icon name="notes-o" color="#0467f3" size="20" />
          </div>
          <div class="txt">今日</div>
        </div>
        <div class="item" @click="selectDate(2)">
          <div class="icon">
            <van-icon name="notes-o" color="#0467f3" size="20" />
          </div>
          <div class="txt">最近一周</div>
        </div>
        <div class="item" @click="selectDate(3)">
          <div class="icon">
            <van-icon name="notes-o" color="#0467f3" size="20" />
          </div>
          <div class="txt">最近一个月</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  props: {
    query: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      text: '今日',
      show: false
    }
  },
  mounted(){
    this.selectDate(1)
  },
  methods: {
    selectDate (val) {
      if (val === 1) {
        this.text = '今日'
        this.query.begin_date = this.$moment().format('YYYY-MM-DD')
        this.query.end_date = this.$moment().format('YYYY-MM-DD')
      }
      if (val === 2) {
        this.text = '最近一周'
        this.query.begin_date = this.$moment().day(this.$moment().day() - 6).format('YYYY-MM-DD')
        this.query.end_date = this.$moment().format('YYYY-MM-DD')
      }
      if (val === 3) {
        this.text = '最近一个月'
        this.query.begin_date = this.$moment().subtract(1, 'months').format('YYYY-MM-DD')
        this.query.end_date = this.$moment().format('YYYY-MM-DD')
      }
      // this.$emit('getDataList')
      this.show = false
      this.$parent.getDataList()
    }
  }
}
</script>
<style lang="scss" scoped>
.top {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 0;
  .title {
    flex: 1;
    font-size: 16px;
    font-weight: 700;
  }
}

.items {
  padding: 20px 0 30px;
  .item {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    &:active {
      background-color: rgba(25, 137, 250, 0.2);
    }
    .icon {
      width: 36px;
      height: 36px;
      background-color: rgba(25, 137, 250, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 20px;
    }
    .txt {
      flex: 1;
    }
  }
}
</style>